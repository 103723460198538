<template>
  <div class="index">
    <div class="inner-wrap">
      <div class="banner-wrap homebanner">
        <div class="banner">
          <el-carousel height="254px" arrow="hover" v-loading="loadingAd" @change="handleChange" indicator-position="none">
            <el-carousel-item v-for="item in adList" :key="item.adv_id">
              <el-image :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>

      <p class="jiu">
        <img class="jiu_img" src="~@/assets/imgs/jiu.png" alt="" />
      </p>
    </div>
  </div>
</template>

<script>
  import index from "./_index/index.js"
  export default {
    mixins: [index]
  }
</script>

<style lang="scss" scoped>
  .index {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    width: 100%;
    // background-color: rgba(148, 28, 18, 1);
    background-color: #a20000;
    padding: 0.1rem 0;

    .inner-wrap {
      width: 1210px;
      margin: 0 auto;
      position: relative;
    }

    .banner-wrap {
      height: 460px;
      width: 100%;
      position: relative;
    }

    .banner {
      height: 100%;
      background-image: url("../../assets/imgs/drawer.png");
      background-position: center, center;
      background-size: auto 460px;
      background-repeat: no-repeat;

      .el-image {
        width: 100%;
        height: 254px;
      }

      .el-carousel {
        position: absolute;
        top: 98px;
        left: 160px;
        width: 890px;
      }
    }

    .jiu {
      text-align: right;
      .jiu_img {
        width: 60px;
      }
    }
  }
</style>
